<template>
	<div class="page">
		<lm-header :scrollTop="scrollTop" />
		<div class="banner">
			<div class="layout content">
				<div class="title">客户案例</div>
				<div class="info">勤耕行业，灵妙用数据驱动企业腾飞</div>
				<div class="button" @click="() => {$router.push('/relation')}">立即体验</div>
			</div>
		</div>
		<div class="main">
			<div class="title">他们也在选择<span>灵妙</span></div>
			<div class="cards">
				<div class="card">
					<img src="./assets/ABB2@2x.png" />
					<div class="companyName">ABB</div>
				</div>
				<div class="card">
					<img src="./assets/飞利浦2@2x.png" />
					<div class="companyName">飞利浦</div>
				</div>
				<div class="card">
					<img src="./assets/海纳川2@2x.png" />
					<div class="companyName">海纳川</div>
				</div>
				<div class="card">
					<img src="./assets/华胤钢构2@2x.png" />
					<div class="companyName">华胤钢构</div>
				</div>
				<div class="card">
					<img src="./assets/吉利汽车2@2x.png" />
					<div class="companyName">吉利汽车</div>
				</div>
				<div class="card">
					<img src="./assets/科陆电子2@2x.png" />
					<div class="companyName">科陆电子</div>
				</div>
				<div class="card">
					<img src="./assets/联合科技2@2x.png" />
					<div class="companyName">联合科技</div>
				</div>
				<div class="card">
					<img src="./assets/南瑞继保2@2x.png" />
					<div class="companyName">南瑞继保</div>
				</div>
				<div class="card">
					<img src="./assets/西子电梯2@2x.png" />
					<div class="companyName">西子电梯</div>
				</div>
				<div class="card">
					<img src="./assets/新华昌2@2x.png" />
					<div class="companyName">新华昌</div>
				</div>
				<div class="card">
					<img src="./assets/英纳法2@2x.png" />
					<div class="companyName">英纳法</div>
				</div>
				<div class="card">
					<div class="cooperation">
						<div class="text">相信您的选择</div>
						<router-link tag="div" class="button pointer" to="/relation">洽谈合作</router-link>
					</div>
				</div>
			</div>
		</div>
		<lm-footer />
	</div>
</template>

<script>
import LmHeader from "@/components/header";
import LmFooter from "@/components/footer";
export default {
	components: {
		LmHeader,
		LmFooter,
	},
	data() {
		return {
			scrollTop: 0,
		};
	},
	mounted() {
		window.addEventListener("scroll", this.getScroll);
		this.getScroll();
	},
	methods: {
		getScroll() {
			this.scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
		},
	},
};
</script>

<style lang="less" scoped>
.banner {
	width: 1920px;
	height: 450px;
	background: url("./assets/banner.png") no-repeat;
	background-size: 1920px;
	position: relative;
	.content {
		padding-top: 155px;
		line-height: 1;
		text-align: left;
		color: #ffffff;
		.title {
			font-size: 50px;
			padding-bottom: 30px;
		}
		.info {
			font-size: 22px;
			padding-bottom: 53px;
		}
		.button {
			display: inline-block;
			font-size: 18px;
			padding: 15px 34px;
			background: #ff9000;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.main {
	padding: 50px 0 40px;
	margin: 0 auto;
	width: 1240px;
	.title {
		font-size: 42px;
		span {
			color: #218aef;
		}
	}
	.cards {
		padding: 60px 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.card {
			margin-bottom: 10px;
			width: 295px;
			height: 200px;
			position: relative;
			z-index: 3;
			background: #ffffff;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 295px;
				height: 200px;
				z-index: 2;
				box-shadow: 0px 8px 70px 0px rgba(142, 171, 201, 0.2);
			}
			&:nth-last-of-type(1)::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 295px;
				height: 200px;
				z-index: 2;
				box-shadow: -10px -10px 20px -10px rgba(142, 171, 201, 0.2);
			}
			img {
				width: 135px;
				margin: 39px 0 33px;
			}
			.companyName {
				margin: 0 auto;
				width: 186px;
				box-shadow: 0px -1px 0px 0px #d6e2ea;
				line-height: 60px;
				font-size: 18px;
				color: #666666;
			}
			.cooperation {
				padding-top: 65px;
				.text {
					font-size: 18px;
					color: #666666;
					margin-bottom: 18px;
					line-height: 1;
				}
				.button {
					font-size: 16px;
					color: #ffffff;
					display: inline-block;
					background: #3a9eff;
					border-radius: 4px;
					padding: 8px 14px;
					position: relative;
					z-index: 10;
				}
			}
		}
	}
}
</style>
